import "./output.css";
import { useAuth0 } from "@auth0/auth0-react";
import Login from "./Login";
import { Fragment, useEffect, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { ProfileMenu } from "./ProfileMenu";
import TessituraSettingsPage from "./pages/TessituraSettings";
import TessituraBatchesPage from "./pages/TessituraBatches";
import TessituraCartsPage from "./pages/TessituraCarts";
import {
  IsTessituraTokenValid,
  useTessituraAuthToken,
} from "./functions/TessituraLogin";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Link,
} from "react-router-dom";
import HomePage from "./pages/Home";
import TessituraLogin from "./functions/TessituraLogin";
import ITBudgetsPage from "./pages/Budgets/ITBudgetsPage";

function Navigation() {
  const { isAuthenticated } = useAuth0();
  const location = useLocation();

  const navigation =
    typeof isAuthenticated === "boolean" && isAuthenticated
      ? [
        {
          name: "Tessitura Batches",
          href: "/TessituraBatches",
          current: location.pathname === "/TessituraBatches",
        },
        {
          name: "Tessitura Carts",
          href: "/TessituraCarts",
          current: location.pathname === "/TessituraCarts",
        },
        ]
      : [];

  return (
    <nav className="flex space-x-2">
      {navigation.map((item) => (
        <Link
          key={item.name}
          to={item.href}
          className={`relative flex rounded-md text-md hover:text-sig-pink text-md px-2 py-2 ${
            item.current
              ? "text-sig-pink"
              : "text-white"
          }`}
        >
          {item.name}
        </Link>
      ))}
    </nav>
  );
}

export default function App() {
  const { isAuthenticated } = useAuth0();
  const { user } = useAuth0();
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [isTessituraTokenValid, setIsTessituraTokenValid] = useState(false);
  const { tessituraAuthToken, setTessituraAuthToken } = useTessituraAuthToken();

  useEffect(() => {
    //console.log("running checkTessituraTokenValidity");
    //console.log("tessituraAuthToken:", tessituraAuthToken);
    const checkTessituraTokenValidity = () => {
      const tokenValidity = IsTessituraTokenValid();
      setIsTessituraTokenValid(tokenValidity);
      //console.log("tokenValidity:", isTessituraTokenValid);
      //console.log("token:", tessituraAuthToken);
    };

    checkTessituraTokenValidity();
  }, [tessituraAuthToken]);

  //console.log(isModalOpen);

  function getUserData(type) {
    if (user) {
      if (type === "initials") {
        return user.given_name.charAt(0) + user.family_name.charAt(0);
      } else if (type === "name") {
        return user.name;
      } else if (type === "email") {
        return user.email;
      }
    }
  }

  const userData = {
    initials: getUserData("initials"),
    name: getUserData("name"),
    email: getUserData("email"),
  };

  return (
    <Router>
      <Disclosure as="nav" className="bg-sig-purple">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
              <div className="relative flex h-16 items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button*/}
                  <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="flex flex-shrink-0 items-center">
                    <Link to="/">
                      <img
                        className="absolute left-1/2 transform -translate-x-1/2 top-0 h-16 w-auto z-0"
                        src="https://res.cloudinary.com/signature-theatre/image/upload/v1716325116/Sig_Logo_purple-pink_v2.jpg"
                        alt="Signature Theatre Logo"
                      />
                    </Link>
                  </div>
                  <div className="hidden sm:ml-6 sm:block">
                    <div className="flex space-x-4">
                      <Navigation />
                    </div>
                  </div>
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  {/* <TessituraLogin /> */}
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-3">
                    <div>
                      {isAuthenticated ? (
                        <button
                          onClick={() => setShowProfileMenu(true)}
                          className="relative flex rounded-full bg-sig-pink text-sig-purple text-sm hover:text-sig-pink text-md px-2 py-2 hover:bg-sig-purple"
                        >
                          <ProfileMenu
                            open={showProfileMenu}
                            setOpen={setShowProfileMenu}
                            user={userData}
                          />
                          <span className="absolute -inset-1.5" />
                          <span className="sr-only">Open user menu</span>
                          {getUserData("initials")}
                        </button>
                      ) : (
                        <Login className="relative flex rounded-none bg-transparent text-sm text-sig-pink hover:text-white">
                          <span className="absolute -inset-1.5" />
                          <span className="sr-only">Log In</span>
                        </Login>
                      )}
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    ></Transition>
                  </Menu>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2">
                <Navigation />
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <div className="w-full relative flex justify-center">
        <div className="absolute z-50">
          <TessituraLogin />
        </div>
      </div>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/TessituraSettings" element={<TessituraSettingsPage />} />
        <Route path="/TessituraBatches" element={<TessituraBatchesPage />} />
        <Route path="/TessituraCarts" element={<TessituraCartsPage />} />
        <Route path="/Budgets/IT" element={<ITBudgetsPage />} />
      </Routes>
    </Router>
  );
}
