import { getTessituraUrl } from "./getTessituraUrl";
import { verifyTokenStructure } from "../functions/TessituraLogin";

export async function deleteTessituraSubLineItem(tessituraAuthToken, sessionkey, lineItemId, subLineItemId) {
  if (!tessituraAuthToken) {
    //console.error('No Tessitura Auth Token found');
    return;
  }
  let tokenData = verifyTokenStructure(tessituraAuthToken);
  const { token, expirationDate } = tokenData;
  if (expirationDate < new Date()) {
    console.error("Token expired");
    return; // or throw new Error('No Tessitura Auth Token found');
  }

  const fetch = window.fetch;
  const finalUrl = getTessituraUrl("/Web/Cart/" + sessionkey + "/LineItems/" + lineItemId + "/SubLineItems/" + subLineItemId);
//console.log('finalUrl', finalUrl);
//console.log('token', token, 'sessionkey', sessionkey, 'lineItemId', lineItemId, 'subLineItemId', subLineItemId);
  const response = await fetch(finalUrl, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
  });

  if (response.ok) {
    //console.log("SubLineItem delete successful");
    return true;
  } else {
    //console.log("SubLineItem delete failed", response);
    return false;
  }
}

// Add more functions as needed
