import { getTessituraUrl } from './getTessituraUrl';

export async function tessituraAuth(userName, password) {
  const userData = {
    UserName: userName,
    Password: password,
    MachineLocation: 'WEBUSER',
  };

  const fetch = window.fetch;
  const finalUrl = getTessituraUrl('/Security/Authenticate/Token/Generate');

  const response = await fetch(finalUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // 'Authorization': 'Bearer your-token' (if needed)
    },
    body: JSON.stringify(userData),
  });
  return response.json();
}

// Add more functions as needed