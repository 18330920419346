import { useState, useEffect } from "react";
import {
  logInToTessitura,
  SaveTessituraCredentials,
  GetTessituraSavedCredentials,
} from "./functions/TessituraLogin";
import { useAuth0 } from "@auth0/auth0-react";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";

const TessituraCredentialsForm = ({open, setOpen}) => {
  const [tessituraUsername, setTessituraUsername] = useState("");
  const [tessituraPassword, setTessituraPassword] = useState("");
  const { user } = useAuth0();

  const onTessituraCredentialsFormSubmit = async (event) => {
    event.preventDefault();

    //console.log("Submitting Tessitura Credentials Form");

    try {
    await SaveTessituraCredentials(user, tessituraUsername, tessituraPassword);
    } catch (error) {
        console.error('Unable to save credentials. Error JSON:', JSON.stringify(error, null, 2));}
    setOpen(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 mx-auto my-auto">
          <div>
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
              <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <img
                  className="mx-auto h-10 w-auto"
                  src="https://www.tessitura.com/_next/static/media/tess-logo.75b0cfa3.png"
                  alt="Add Tessitura Credentials Form"
                />
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                  Add Tessitura Credentials
                </h2>
              </div>

              <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form
                  onSubmit={onTessituraCredentialsFormSubmit}
                  className="space-y-6"
                >
                  <div>
                    <label
                      htmlFor="username"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Username
                    </label>
                    <div className="mt-2">
                      <input
                        id="username"
                        name="username"
                        type="text"
                        required
                        value={tessituraUsername}
                        onChange={(e) => setTessituraUsername(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div>
                    <div className="flex items-center justify-between">
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Password
                      </label>
                      <div className="text-sm"></div>
                    </div>
                    <div className="mt-2">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        value={tessituraPassword}
                        onChange={(e) => setTessituraPassword(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="flex w-full justify-center rounded-md !bg-slate-100 !text-orange-800 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:!bg-slate-800 hover:!text-orange-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Dialog.Panel>
        </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default TessituraCredentialsForm;
