import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export const Logout = ( {className}) => {
    const { logout } = useAuth0();
    
    return (
        <div className={className} onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
            Log Out
        </div>
        );
    };