// TessituraSettings.js
import React, { useEffect, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { TessituraCartSearch } from "../functions/TessituraCartMaintenance";
import { useNavigate } from "react-router-dom";
import { TessituraAuthTokenContext } from "../functions/TessituraLogin";

const TessituraCartsPage = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate();
  const { tessituraAuthToken } = useContext(TessituraAuthTokenContext);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated, isLoading, navigate, tessituraAuthToken]);

  return (
    <div className="relative h-screen">
    <TessituraCartSearch key={tessituraAuthToken}/>
      {!tessituraAuthToken && (
        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
      )}
    </div>
  );
};

export default TessituraCartsPage;
