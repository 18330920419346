//import { useEnsureTessituraLoginState } from "./TessituraLogin";
import isTessituraTokenValid from "./TessituraLogin";
import { getTessituraCarts } from "../apis/getTessituraCarts";
import { React, useState, useEffect, useContext } from "react";
import { deleteTessituraCart } from "../apis/deleteTessituraCart";
import { deleteTessituraSubLineItem } from "../apis/deleteTessituraSubLineItem";
import { TessituraAuthTokenContext } from "../functions/TessituraLogin";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

Modal.setAppElement("#root");

export const TessituraCartSearch = () => {
  const { tessituraAuthToken } = useContext(TessituraAuthTokenContext);
  const [orderStartDate, setOrderStartDate] = useState("");
  const [orderEndDate, setOrderEndDate] = useState("");
  const [performanceId, setPerformanceId] = useState("");
  const [seatRow, setSeatRow] = useState("");
  const [seatNumber, setSeatNumber] = useState("");
  const [seasonId, setSeasonId] = useState("");
  const [tessituraCarts, setTessituraCarts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedPerformance, setSelectedPerformance] = useState(null);
  const [isSearchPressed, setIsSearchPressed] = useState(false);

  useEffect(() => {
    //console.log("tessituraCarts:", tessituraCarts);
  }, [searchPerformed]);

  const handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }
    setSearchPerformed(false);
    setIsLoading(true);
    setIsSearchPressed(true);
    //console.log("orderStartDate:", orderStartDate);
    const carts = await getTessituraCarts(
      tessituraAuthToken,
      performanceId,
      seatRow,
      seatNumber,
      orderStartDate,
      orderEndDate,
      seasonId
    );
    //console.log("cartslength:", carts.length);
    //console.log("carts:", carts);
    setTessituraCarts(carts);
    setIsLoading(false);
    setSearchPerformed(true);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
    <div
      className={`${
        isSearchPressed ? "mt-0 flex flex-col items-start justify-start" : "flex flex-col items-center justify-center mt-1/4-screen"
      }  `}
    >
      <form
        className="flex justify-center space-x-4 mb-6 mt-8"
        onSubmit={handleSubmit}
      >
        <label className="mb-2">
          Start Date:
          <input
            className="rounded-md shadow-md ml-2"
            type="date"
            value={orderStartDate}
            onChange={(e) => setOrderStartDate(e.target.value)}
          />
        </label>
        <label className="mb-2">
          End Date:
          <input
            className="rounded-md shadow-md ml-2"
            type="date"
            value={orderEndDate}
            onChange={(e) => setOrderEndDate(e.target.value)}
          />
        </label>
        <button
          type="submit"
          className="relative flex rounded-md text-md px-2 py-2 shadow-md h-full"
        >
          Search
        </button>
      </form>
      {isLoading ? (
        <p>Searching...</p>
      ) : tessituraCarts && Object.keys(tessituraCarts).length > 0 ? (
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table>
                  <thead>
                    <tr>
                      <th scope="col">Order Number</th>
                      <th scope="col">Order Date</th>
                      <th scope="col">Customer Number</th>
                      <th scope="col">Details</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(tessituraCarts).map((key, index) => {
                      const cart = tessituraCarts[key];
                      return (
                        <tr key={index}>
                          <th>{key}</th>
                          <td>
                            {new Date(cart.order_dt).toLocaleDateString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                              }
                            )}
                          </td>
                          <td>{cart.customer_no}</td>
                          <td>
                            <button
                              onClick={() =>
                                setSelectedOrder({ orderId: key, ...cart })
                              }
                            >
                              View Details
                            </button>
                          </td>
                          <td>
                            <button
                              onClick={async () => {
                                const result = await deleteTessituraCart(tessituraAuthToken, cart.sessionkey);
                                if (result) {
                                  handleSubmit();
                                }
                              }}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : searchPerformed ? (
        <p>No carts found.</p>
      ) : (
        <p></p>
      )}
      <Modal
        isOpen={!!selectedOrder}
        onRequestClose={() => setSelectedOrder(null)}
        contentLabel="Order Details"
        className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-3/4 h-3/4 overflow-auto bg-gray-50 rounded shadow-lg p-8"
      >
        {selectedOrder && (
          <div className="text-xs text-gray-700 uppercase">
            <h2 className="text-base text-black">
              Order Number: {selectedOrder.orderId}
            </h2>
            <p>
              Order Date:{" "}
              {new Date(selectedOrder.order_dt).toLocaleDateString("en-US", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })}
            </p>
            <p>Customer Number: {selectedOrder.customer_no}</p>
            <h3 className="mt-4 text-base">Performances</h3>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Date</th>
                  <th>Name</th>
                  <th>Season</th>
                  <th>Details</th>
                  {/* Add more headers as needed... */}
                </tr>
              </thead>
              <tbody>
                {Object.keys(selectedOrder.performances).map((key, index) => {
                  const performance = selectedOrder.performances[key];
                  return (
                    <tr key={index}>
                      <td>{key}</td>
                      <td>
                        {new Date(performance.perf_dt).toLocaleDateString(
                          "en-US",
                          {
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                          }
                        )}
                      </td>
                      <td>{performance.perf_name}</td>
                      <td>{performance.season}</td>
                      <td>
                        <button
                          onClick={() =>
                            setSelectedPerformance({
                              performanceId: key,
                              ...performance,
                            })
                          }
                        >
                          View Details
                        </button>
                      </td>
                      {/* Display performance details here... */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <button
              onClick={() => setSelectedOrder(null)}
              className="absolute top-0 right-0 p-2 text-base"
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        )}
        <Modal
          isOpen={!!selectedPerformance}
          onRequestClose={() => setSelectedPerformance(null)}
          contentLabel="Performance Details"
          className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-3/4 h-3/4 overflow-auto bg-white rounded shadow-lg p-8"
        >
          {selectedPerformance && (
            <div className="text-xs text-gray-700 uppercase">
              <h2 className="text-base text-black">Performance Details</h2>
              <p>ID: {selectedPerformance.performanceId}</p>
              <p>
                Date:
                {new Date(selectedPerformance.perf_dt).toLocaleDateString(
                  "en-US",
                  {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  }
                )}
              </p>
              <p>Name: {selectedPerformance.perf_name}</p>
              <p>Season: {selectedPerformance.season}</p>
              <h3 className="mt-4 text-base">Seats</h3>
              <table>
                <thead>
                  <tr>
                    <th>Seat Number</th>
                    <th>Seat Row</th>
                    <th>SubLineItem Id</th>
                    <th>LineItem Id</th>
                    <th></th>
                    {/* Add more headers as needed... */}
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(selectedPerformance.subLineItems).map(
                    (key, index) => {
                      const subLineItem = selectedPerformance.subLineItems[key];
                      return (
                        <tr key={index}>
                          <td>{subLineItem.seat_num}</td>
                          <td>{subLineItem.seat_row}</td>
                          <td>{subLineItem.sli_no}</td>
                          <td>{subLineItem.li_seq_no}</td>
                          <td>
                            <button
                              onClick={() =>
                                deleteTessituraSubLineItem(
                                  tessituraAuthToken,
                                  selectedOrder.sessionkey,
                                  subLineItem.li_seq_no,
                                  subLineItem.sli_no
                                )
                              }
                            >
                              
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </td>
                          {/* Display performance details here... */}
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
              {/* Add more fields as needed... */}
              <button
                onClick={() => setSelectedPerformance(null)}
                className="absolute top-0 right-0 p-2 text-base"
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          )}
        </Modal>
      </Modal>
    </div>
    </div>
  );
};
