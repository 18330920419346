import { getTessituraUrl } from "./getTessituraUrl";
import { verifyTokenStructure } from "../functions/TessituraLogin";

export async function deleteTessituraCart(tessituraAuthToken, sessionkey) {
  if (!tessituraAuthToken) {
    //console.error('No Tessitura Auth Token found');
    return;
  }
  let tokenData = verifyTokenStructure(tessituraAuthToken);
  const { token, expirationDate } = tokenData;
  if (expirationDate < new Date()) {
    console.error("Token expired");
    return; // or throw new Error('No Tessitura Auth Token found');
  }

  const fetch = window.fetch;
  const finalUrl = getTessituraUrl("/Web/Cart/" + sessionkey);
//console.log('sessionkey',sessionkey, 'tessituraAuthToken',tessituraAuthToken);
  const response = await fetch(finalUrl, {
    method: "DELETE",
    headers: {
      Authorization: "Token " + token,
    },
  });

  if (response.ok) {
    //console.log("Cart delete successful");
    return true;
  } else {
    //console.log("Cart delete failed");
    //console.log(response);
    return false;
  }
}

// Add more functions as needed
