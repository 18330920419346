// import fetch from 'node-fetch';

export function getTessituraUrl(urlExtension) {
    
    const tessituraEnvUrl = `${process.env.REACT_APP_TESSITURA_ENV === 'prod' ? process.env.REACT_APP_TESSITURA_ENV_PROD_URL : process.env.REACT_APP_TESSITURA_ENV_TEST_URL}${urlExtension}`;
    const encodedTessituraEnvUrl = encodeURIComponent(tessituraEnvUrl);
    const cloudflareWorkerUrl = process.env.REACT_APP_CLOUDFLARE_WORKER_URL;
    const finalUrl = `${cloudflareWorkerUrl}?api=${encodedTessituraEnvUrl}`;
  
    return finalUrl;
  }