import React, { useState } from 'react';
import Papa from 'papaparse';
import Modal from 'react-modal';

const ITBudgets = () => {
  const [file, setFile] = useState(null);
  const [headers, setHeaders] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const standardFields = ['Field1', 'Field2', 'Field3']; // replace with your standard fields

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    Papa.parse(file, {
      header: true,
      complete: function(results) {
        setHeaders(results.meta.fields);
        setModalIsOpen(true);
      }
    });
  };

  const handleModalSubmit = (e) => {
    e.preventDefault();
    // save the mappings from the form
    // close the modal
    setModalIsOpen(false);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input type="file" onChange={handleFileChange} />
        <button type="submit">Upload</button>
      </form>

      <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)}>
        <form onSubmit={handleModalSubmit}>
          {standardFields.map((field) => (
            <div key={field}>
              <label>{field}</label>
              <select>
                {headers.map((header) => (
                  <option key={header} value={header}>{header}</option>
                ))}
              </select>
            </div>
          ))}
          <button type="submit">Save</button>
        </form>
      </Modal>
    </div>
  );
};

export default ITBudgets;