import { useState, useEffect, createContext, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { validateTessituraToken } from "../apis/validateTessituraToken";
import { tessituraAuth } from "../apis/tessituraAuth";
import { DynamoDB } from "aws-sdk";
import Draggable from "react-draggable";

// Create a context for the token
export const TessituraAuthTokenContext = createContext();

// Create a provider component for the context
export const TessituraAuthTokenProvider = ({ children }) => {
  const [tessituraAuthToken, setTessituraAuthToken] = useState(
    sessionStorage.getItem("tessituraAuthToken")
  );

  return (
    <TessituraAuthTokenContext.Provider
      value={{ tessituraAuthToken, setTessituraAuthToken }}
    >
      {children}
    </TessituraAuthTokenContext.Provider>
  );
};

// Create a custom hook to use the context
export const useTessituraAuthToken = () =>
  useContext(TessituraAuthTokenContext);

export const SaveTessituraCredentials = async (
  appUser,
  tessituraUsername,
  tessituraPassword
) => {
  var CryptoJS = require("crypto-js");
  const encryptedTessituraPassword = CryptoJS.AES.encrypt(
    tessituraPassword,
    process.env.REACT_APP_CRYPTO_SECRET
  ).toString();
  const dynamoDb = new DynamoDB.DocumentClient();
  const params = {
    TableName: process.env.REACT_APP_DYNAMODB_TABLE_NAME,
    Item: {
      username: appUser.email,
      tessituraUsername: tessituraUsername,
      tessituraPassword: encryptedTessituraPassword,
      primary: "true",
    },
  };
  //console.log("Adding a new item...")
  try {
    await dynamoDb.put(params).promise();
  } catch (error) {
    console.error(
      "Unable to add item. Error JSON:",
      JSON.stringify(error, null, 2)
    );
  }
};

export const GetTessituraSavedCredentials = async (appUser) => {
  if (!appUser) {
    return;
  }
  var CryptoJS = require("crypto-js");
  //console.log(appUser.email)
  const dynamoDb = new DynamoDB.DocumentClient();
  const params = {
    TableName: process.env.REACT_APP_DYNAMODB_TABLE_NAME,
    Key: {
      username: appUser.email,
    },
  };

  try {
    const result = await dynamoDb.get(params).promise();
    if (result.Item) {
      const encryptedTessituraPassword = result.Item.tessituraPassword;
      const bytes = CryptoJS.AES.decrypt(
        encryptedTessituraPassword,
        process.env.REACT_APP_CRYPTO_SECRET
      );
      const tessituraPassword = bytes.toString(CryptoJS.enc.Utf8);
      //console.log(result.Item.tessituraUsername, tessituraPassword, result.Item.primary);
      return {
        tessituraUsername: result.Item.tessituraUsername,
        tessituraPassword: tessituraPassword,
        primary: result.Item.primary,
      };
    } else {
      console.error("No credentials found");
    }
  } catch (error) {
    console.error(
      "Unable to check form completion. Error JSON:",
      JSON.stringify(error, null, 2)
    );
  }
};

export const logInToTessitura = async (
  tessituraUsername,
  tessituraPassword,
  setTessituraAuthToken
) => {
  try {
    const response = await tessituraAuth(tessituraUsername, tessituraPassword);
    const token = response.Token;

    if (token) {
      const expirationDate = new Date();
      expirationDate.setMinutes(expirationDate.getMinutes() + 15); // Set the expiration date to 1 hour from now

      const tokenData = {
        token,
        expirationDate: expirationDate.toISOString(), // Convert the Date object to a string
      };

      sessionStorage.setItem("tessituraAuthToken", JSON.stringify(tokenData)); // Stringify the JSON object
      setTessituraAuthToken(tokenData); // Update the context

      return token;
    } else {
      console.error("Unable to authenticate with Tessitura");
    }
  } catch (error) {
    console.error(
      "Unable to authenticate with Tessitura. Error JSON:",
      JSON.stringify(error, null, 2)
    );
  }
};

export const IsTessituraTokenValid = () => {
  let TessituraTokenValidity = false;

  try {
    const tessituraAuthToken = sessionStorage.getItem("tessituraAuthToken");
    if (tessituraAuthToken) {
      if (typeof tessituraAuthToken !== 'string') {
        console.error('tessituraAuthToken should be a string');
        return;
      }
      const { token, expirationDate } = JSON.parse(tessituraAuthToken);

      const currentDateTime = new Date();
      const tokenExpirationDate = new Date(expirationDate);

      if (currentDateTime < tokenExpirationDate) {
        TessituraTokenValidity = true;
      } else {
        TessituraTokenValidity = false;
        sessionStorage.removeItem("tessituraAuthToken");
      }
    } else {
      TessituraTokenValidity = false;
      sessionStorage.removeItem("tessituraAuthToken");
    }
  } catch (error) {
    console.error(
      "Unable to validate Tessitura token. Error JSON:",
      JSON.stringify(error, null, 2)
    );
    TessituraTokenValidity = false;
    sessionStorage.removeItem("tessituraAuthToken");
  }

  return TessituraTokenValidity;
};

/* export const useEnsureTessituraLoginState = () => {
  const { user } = useAuth0();
  const [isTessituraTokenValid, setIsTessituraTokenValid] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkTessituraTokenValidity = async () => {
      try {
        const tessituraAuthToken = sessionStorage.getItem('tessituraAuthToken');
        if (tessituraAuthToken) {
          const response = await validateTessituraToken(tessituraAuthToken);
          //console.log('token response:', response);
          setIsTessituraTokenValid((prevIsValid) => !!response || prevIsValid);
        } else {
          setIsTessituraTokenValid(false);
        }

        setIsTessituraTokenValid(async (prevIsValid) => {
          if (!prevIsValid) {
            const credentials = await GetTessituraSavedCredentials(user);
            if (credentials) {
              await logInToTessitura(credentials.tessituraUsername, credentials.tessituraPassword);
            }
          }
          return prevIsValid;
        });

        setLoading(false)
      } catch (error) {
        console.error('Unable to validate Tessitura token. Error JSON:', JSON.stringify(error, null, 2));
        setIsTessituraTokenValid(false);
        setLoading(false);
      }
    };
  
    checkTessituraTokenValidity();
  }, [user]);
  //console.log("isTessituraTokenValid", isTessituraTokenValid)
  return [isTessituraTokenValid, loading];
}; */

const TessituraLogin = () => {
  const { tessituraAuthToken, setTessituraAuthToken } = useTessituraAuthToken();
  const { user } = useAuth0();

  const handleLogin = async () => {
    if (tessituraAuthToken) {
      sessionStorage.removeItem("tessituraAuthToken");
      setTessituraAuthToken(null);
    } else {
      const credentials = await GetTessituraSavedCredentials(user);
      if (credentials) {
        await logInToTessitura(
          credentials.tessituraUsername,
          credentials.tessituraPassword,
          setTessituraAuthToken
        );
      }
    }
  };

  return (
    <div className="flex justify-center">
      <div className="group relative w-auto h-6 rounded-b-lg hover:h-16 hover:w-64 transition-all duration-200 transform origin-top">
        <div className="flex flex-col justify-between h-full px-2 bg-slate-800 rounded-b-lg">
          <div className="flex items-center group-hover:invisible">
            <div
              className={`w-2 h-2 rounded-full mr-2 ${
                tessituraAuthToken ? "bg-green-500" : "bg-red-500"
              }`}
            ></div>
            <span className="text-white">Tessitura</span>
          </div>
        </div>
        <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-200">
          <div className="flex flex-col items-center rounded-b-lg">
          <div className="flex items-center">

            <div
              className={`w-2 h-2 rounded-full mr-2 mb-2 ${
                tessituraAuthToken ? "bg-green-500" : "bg-red-500"
              }`}
            ></div>
            <span className="mb-2 text-white">
              {tessituraAuthToken
                ? "Connected to Tessitura"
                : "Not connected to Tessitura"}
            </span>
            </div>
            <button
              onClick={handleLogin}
              className="px-2 py-1 bg-sig-pink text-sig-purple rounded text-xs"
            >
              {tessituraAuthToken ? "Disconnect" : "Connect"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const verifyTokenStructure = (tessituraAuthToken) => {
  let tokenData;

  if (typeof tessituraAuthToken === 'string') {
    tokenData = JSON.parse(tessituraAuthToken);
  } else if (typeof tessituraAuthToken === 'object' && tessituraAuthToken !== null) {
    tokenData = tessituraAuthToken;
  } else if (tessituraAuthToken === null) {
    //console.error('User must login to Tessitura');
    return;
  } else {
    console.error('tessituraAuthToken should be a string or an object', typeof tessituraAuthToken, tessituraAuthToken);
    return;
  }
    //console.log('tessituraAuthToken', tessituraAuthToken)

    return tokenData;
};

export default TessituraLogin;
