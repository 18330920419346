import { getTessituraUrl } from "./getTessituraUrl";
import { verifyTokenStructure } from "../functions/TessituraLogin";

export async function getTessituraBatches(
  creator,
  queryStartDate,
  queryEndDate,
  tessituraAuthToken
) {
  //console.log('starting batch call')
  if (!tessituraAuthToken) {
    //console.error('No Tessitura Auth Token found');
    return;
  }
  let tokenData = verifyTokenStructure(tessituraAuthToken);
  const { token, expirationDate } = tokenData;
  const body = {
    ProcedureId: 41,
    ProcedureName: "LP_BATCH_QUERY_UTILITY",
    ParameterValues: [
      {
        Name: "batch_creator",
        Value: creator,
      },
      {
        Name: "query_start_date",
        Value: queryStartDate,
      },
      {
        Name: "query_end_date",
        Value: queryEndDate,
      },
    ],
  };

  const fetch = window.fetch;
  const finalUrl = getTessituraUrl("/Custom/Execute");

  try {
    const response = await fetch(finalUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + token,
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.text();

    const parser = new window.xml2js.Parser();
    let result;
    parser.parseString(data, (err, parsedResult) => {
      if (err) {
        console.error("Error parsing XML", err);
      } else {
        result = parsedResult.ExecuteLocalProcedureResults.Table.map(
          (item) => ({
            batch_no: item.batch_no[0],
            batch_type: item.batch_type[0],
            status: item.status[0],
            created_by: item.created_by[0],
            create_dt: item.create_dt[0],
          })
        );
      }
    });

    //console.log("Batches response:",result)
    return result;
  } catch (error) {
    if (error.message.includes("401")) {
      // Perform login again
      //console.log("401 error");
    } else {
      throw error;
    }
  }
}

// Add more functions as needed
