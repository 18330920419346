import React from 'react';
import ITBudgets from '../../functions/ITBudgets';

const ITBudgetsPage = () => {
  return (
    <div >
      <ITBudgets />
    </div>
  );
};

export default ITBudgetsPage;