import { getTessituraUrl } from "./getTessituraUrl";
import { verifyTokenStructure } from "../functions/TessituraLogin";

export async function editTessituraBatch(batch_no, status, tessituraAuthToken) {
  if (!tessituraAuthToken) {
    //console.error('No Tessitura Auth Token found');
    return;
  }
  let tokenData = verifyTokenStructure(tessituraAuthToken);
  const { token, expirationDate } = tokenData;
  const body = {
    ProcedureId: 42,
    ProcedureName: "LP_BATCH_HOLDING_UTILITY",
    ParameterValues: [
      {
        Name: "batch_no",
        Value: batch_no,
      },
      {
        Name: "batch_status",
        Value: status,
      },
    ],
  };

  const fetch = window.fetch;
  const finalUrl = getTessituraUrl("/Custom/Execute");

  const response = await fetch(finalUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
    body: JSON.stringify(body),
  });

  const data = await response.text();

  const parser = new window.xml2js.Parser();
  let result;
  parser.parseString(data, (err, parsedResult) => {
    if (err) {
      console.error("Error parsing XML", err);
    } else {
      if (!parsedResult.ExecuteLocalProcedureResults) {
        //console.log("Batch edit successful");
        result = true;
      }
    }
  });

  console.log("Batches response:", result);
  return result;
}

// Add more functions as needed
