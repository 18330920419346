// TessituraSettings.js
import React from "react";
import { useState, useEffect } from "react";
import { GetTessituraSavedCredentials } from "../functions/TessituraLogin";
import { useAuth0 } from "@auth0/auth0-react";
import TessituraCredentialsForm from "../TessituraCredentialsForm";
import { Switch } from "@headlessui/react";
import { useNavigate } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const TessituraSettingsPage = () => {
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [automaticTimezoneEnabled, setAutomaticTimezoneEnabled] =
    useState(true);
  const { user } = useAuth0();
  const [isTessituraCredentialsFormOpen, setIsTessituraCredentialsFormOpen] =
    useState(false);
  const [savedTessituraCredentials, setSavedTessituraCredentials] =
    useState(false);

  useEffect(() => {
    const loadTessituraCredentials = async () => {
      const credentials = await GetTessituraSavedCredentials(user);
      setSavedTessituraCredentials(credentials);
    };

    loadTessituraCredentials();
  }, [user]);

  return (
    <>
      <div className="mx-auto max-w-7xl pt-16 lg:flex lg:gap-x-16 lg:px-8">
        <main className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">
          <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Tessitura Credentials
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-500"></p>

              <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                {savedTessituraCredentials ? (
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                      Tessitura Username
                    </dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-gray-900">
                        {savedTessituraCredentials
                          ? savedTessituraCredentials.tessituraUsername
                          : "false"}
                      </div>
                      <button
                        type="button"
                        onClick={() => setIsTessituraCredentialsFormOpen(true)}
                        className="font-semibold text-indigo-600 hover:text-indigo-500"
                      >
                        Update
                      </button>
                    </dd>
                  </div>
                ) : (
                  <div className="pt-6 sm:flex justify-center">
                    <button
                      type="button"
                      onClick={() => setIsTessituraCredentialsFormOpen(true)}
                      className="font-semibold text-indigo-600 hover:text-indigo-500"
                    >
                      Add Tessitura Credentials
                    </button>
                  </div>
                )}

                {isTessituraCredentialsFormOpen && (
                  <TessituraCredentialsForm
                    open={isTessituraCredentialsFormOpen}
                    setOpen={setIsTessituraCredentialsFormOpen}
                  />
                )}
              </dl>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default TessituraSettingsPage;
