import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Logout } from "./Logout";
import { useNavigate } from "react-router-dom";

export function ProfileMenu({ open, setOpen, user }) {
    const navigate = useNavigate();
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay
              className="fixed inset-0 bg-black opacity-50 z-0"
              onClick={() => setOpen(false)}
            />
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="h-48 bg-sig-purple border-b border-gray-300">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-center py-10">
                          <Dialog.Title>
                            <div className="flex justify-center">
                              <span className="inline-block rounded-full w-12 h-12 flex items-center justify-center text-xl bg-sig-pink text-sig-purple">
                                {user.initials}
                              </span>
                            </div>
                            <div className="flex justify-center font-bold text-large text-sig-pink">
                              <span>{user.name}</span>
                            </div>
                            <div className="flex justify-center text-sig-pink">
                              <span>{user.email}</span>
                            </div>
                            <div className="flex justify-center py-2">
                              <span className="px-5">
                                <button className="border-solid shadow-2 rounded-md bg-sig-pink text-sig-purple px-2 py-2 text-sm hover:text-sig-pink hover:bg-sig-purple" 
                                    onClick={() => {setOpen(false);
                                    navigate("/TessituraSettings");}}
                                    >
                                        My Tessitura
                                </button>
                              </span>
                              <span className="px-5">
                                <button className="border-solid rounded-md bg-sig-pink text-sig-purple px-2 py-2 text-sm hover:text-sig-pink hover:bg-sig-purple">
                                  <Logout />
                                </button>
                              </span>
                            </div>
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center"></div>
                        </div>
                      </div>
                    </div>

                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      {/* Your content */}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
