import React from 'react';
import ReactDOM from 'react-dom/client';
import './tailwind.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import AWS from 'aws-sdk';
import { TessituraAuthTokenProvider } from './functions/TessituraLogin';

AWS.config.update({ 
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="dev-ul1jw7vsfa4n3o0q.us.auth0.com"
      clientId="gcK0M4asS3pbZv6hbO2KzdHqDtgqjPL1"
      organization="sigtheatre"
      authorizationParams={{
        redirect_uri: window.location.origin,
        // any other authorization params you need
      }}>
        <TessituraAuthTokenProvider>
      <App />
      </TessituraAuthTokenProvider>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
