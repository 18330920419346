import { getTessituraUrl } from "./getTessituraUrl";
import { verifyTokenStructure } from "../functions/TessituraLogin";

export async function getTessituraCarts(
  tessituraAuthToken,
  performanceId,
  seatRow,
  seatNumber,
  orderStartDate,
  orderEndDate,
  seasonId
) {
  performanceId = performanceId || null;
  seatRow = seatRow || null;
  seatNumber = seatNumber || null;
  orderStartDate = orderStartDate || null;
  orderEndDate = orderEndDate || null;
  seasonId = seasonId || null;

  //console.log('orderStartDate:', orderStartDate)
  if (!tessituraAuthToken) {
    //console.error('No Tessitura Auth Token found');
    return;
  }
  let tokenData = verifyTokenStructure(tessituraAuthToken);
  const { token, expirationDate } = tokenData;
  //console.log('tokenCart', token);
  if (expirationDate < new Date()) {
    console.error("Token expired");
    return; // or throw new Error('No Tessitura Auth Token found');
  }
  const body = {
    ProcedureId: 43,
    ProcedureName: "LP_SIGTHE_LOOKUP_CART",
    ParameterValues: [
      {
        Name: "perf_no",
        Value: performanceId,
      },
      {
        Name: "seat_row",
        Value: seatRow,
      },
      {
        Name: "seat_num",
        Value: seatNumber,
      },
      {
        Name: "order_start_dt",
        Value: orderStartDate,
      },
      {
        Name: "order_end_dt",
        Value: orderEndDate,
      },
      {
        Name: "season_no",
        Value: seasonId,
      },
    ],
  };
  //console.log('body', body);

  const fetch = window.fetch;
  const finalUrl = getTessituraUrl("/Custom/Execute");

  try {
    const response = await fetch(finalUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + token,
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.text();
    //console.log('data', data);

    const parser = new window.xml2js.Parser();
    let result;
    parser.parseString(data, (err, parsedResult) => {
      if (err) {
        console.error("Error parsing XML", err);
      } else {
        if (
          parsedResult.ExecuteLocalProcedureResults &&
          parsedResult.ExecuteLocalProcedureResults.Table
        ) {
          result = parsedResult.ExecuteLocalProcedureResults.Table.reduce(
            (acc, item) => {
              const order_no = item.order_no[0];
              const order = {
                order_dt: item.order_dt[0],
                customer_no: item.customer_no[0],
                fname: item.fname?.[0],
                lname: item.lname[0],
                mos: item.mos[0],
                sessionkey: item.sessionkey[0],
              };
              const performance = {
                perf_no: item.perf_no[0],
                perf_dt: item.perf_dt[0],
                perf_name: item.perf_name[0],
                season: item.season[0],
              };
              const subLineItem = {
                seat_row: item.seat_row[0],
                seat_num: item.seat_num[0],
                sli_no: item.sli_no[0],
                li_seq_no: item.li_seq_no[0],
              };

              if (!acc[order_no]) {
                // If the order doesn't exist, create a new order with a performances object
                acc[order_no] = {
                  ...order,
                  performances: {
                    [performance.perf_no]: { ...performance, subLineItems: [subLineItem] },
                  },
                };
              } else {
                // If the order exists, check if the performance exists within that order
                if (!acc[order_no].performances[performance.perf_no]) {
                  // If the performance doesn't exist, add a new performance with a seats array
                  acc[order_no].performances[performance.perf_no] = {
                    ...performance,
                    subLineItems: [subLineItem],
                  };
                } else {
                  // If the performance exists, add the seat to the seats array of that performance
                  acc[order_no].performances[performance.perf_no].subLineItems.push(
                    subLineItem
                  );
                }
              }

              return acc;
            },
            {}
          );
        } else {
          console.error(
            "Error: ExecuteLocalProcedureResults.Table is undefined"
          );
          return {};
        }
      }
    });

    //console.log("Batches response:",result)
    return result;
  } catch (error) {
    if (error.message.includes("401")) {
      // Perform login again
      //console.log("401 error");
    } else {
      throw error;
    }
  }
}

// Add more functions as needed
