//import { useEnsureTessituraLoginState } from "./TessituraLogin";
import isTessituraTokenValid from "./TessituraLogin";
import { getTessituraBatches } from "../apis/getTessituraBatches";
import { useState, useEffect, useContext } from "react";
import { editTessituraBatch } from "../apis/editTessituraBatch";
import { TessituraAuthTokenContext } from "../functions/TessituraLogin";
import { tessituraAuth } from "../apis/tessituraAuth";

export const ListTessituraBatches = ({
  creator,
  queryStartDate,
  queryEndDate,
}) => {
  const { tessituraAuthToken } = useContext(TessituraAuthTokenContext);
  const [tessituraBatches, setTessituraBatches] = useState(null);
  //const [isTessituraTokenValid, loading] = useEnsureTessituraLoginState();
  const [reload, setReload] = useState(false);
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [searchStartDate, setSearchStartDate] = useState(queryStartDate);
  const [searchEndDate, setSearchEndDate] = useState(queryEndDate);
  const [searchCreator, setSearchCreator] = useState(creator);
  const [searchBatchStatus, setSearchBatchStatus] = useState("O");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchTessituraBatches = async () => {
      setIsLoading(true);
      const batches = await getTessituraBatches(
        creator,
        queryStartDate,
        queryEndDate,
        tessituraAuthToken
      );
      //console.log(batches);
      setIsLoading(false);
      setTessituraBatches(batches);
    };

    const checkTokenAndFetchBatches = async () => {
      //console.log("fetching batches", isTessituraTokenValid)
      const tokenIsValid = isTessituraTokenValid;
      if (tokenIsValid) {
        fetchTessituraBatches();
        //console.log("batches fetched", isTessituraTokenValid)
      }
    };

    checkTokenAndFetchBatches();
  }, [
    creator,
    queryStartDate,
    queryEndDate,
    isTessituraTokenValid,
    reload,
    //loading,
  ]);

  /* if (loading) {
    return <div>Loading...</div>;
  } */

  const handleEditBatch = async (batch_no, status) => {
    //console.log("editing batch", batch_no, status, tessituraAuthToken);
    const response = await editTessituraBatch(
      batch_no,
      status,
      tessituraAuthToken
    );
    if (response) {
      setReload(!reload);
    }
  };

  const handleHeaderClick = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortDirection("asc");
    }
  };

  const sortedBatches = tessituraBatches?.sort((a, b) => {
    if (sortField && sortDirection) {
      if (a[sortField] < b[sortField]) {
        return sortDirection === "asc" ? -1 : 1;
      }
      if (a[sortField] > b[sortField]) {
        return sortDirection === "asc" ? 1 : -1;
      }
    }
    return 0;
  });

  const filteredBatches = sortedBatches?.filter((batch) => {
    return (
      (!searchStartDate || batch.create_dt >= searchStartDate) &&
      (!searchEndDate || batch.create_dt <= searchEndDate) &&
      (!searchCreator || batch.created_by.includes(searchCreator)) &&
      (!searchBatchStatus || batch.status.includes(searchBatchStatus))
    );
  });

  if (isLoading) {
    return (
      <div className="relative top-0 left-0 z-50 w-screen h-screen flex items-center justify-center bg-black">
        <img
          src="https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExa2RqaTR2Y3F0eWRjcXAzMDFxazdqOGVibzk2cG9lYWY0bXpxMzJ2MSZlcD12MV9naWZzX3NlYXJjaCZjdD1n/uIJBFZoOaifHf52MER/giphy.gif"
          alt="Loading..."
        />
      </div>
    );
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold leading-6 text-gray-900 mt-10">
            Tessitura Batches
          </h1>
        </div>
      </div>

      <div className="mb-4 mt-8 justify-start items-start">
        <div className="flex space-x-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Start Date
            </label>
            <input
              type="date"
              className="rounded-md shadow-md h-10"
              value={searchStartDate}
              onChange={(e) => setSearchStartDate(e.target.value)}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              End Date
            </label>
            <input
              type="date"
              className="rounded-md shadow-md h-10"
              value={searchEndDate}
              onChange={(e) => setSearchEndDate(e.target.value)}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Created By
            </label>
            <input
              type="text"
              className="rounded-md shadow-md h-10"
              value={searchCreator}
              onChange={(e) => setSearchCreator(e.target.value)}
              placeholder="Created by"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Status
            </label>
            <select
              className="rounded-md shadow-md pl-2 w-24 h-10"
              value={searchBatchStatus || "Open"}
              onChange={(e) => setSearchBatchStatus(e.target.value)}
            >
              <option value="O">Open</option>
              <option value="H">Held</option>
            </select>
          </div>
        </div>
      </div>

      <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table>
              <thead>
                <tr>
                  <th scope="col" onClick={() => handleHeaderClick("batch_no")}>
                    Batch Number
                    {sortField === "batch_no" &&
                      (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                  <th
                    scope="col"
                    onClick={() => handleHeaderClick("batch_type")}
                  >
                    Batch Type
                    {sortField === "batch_type" &&
                      (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                  <th scope="col" onClick={() => handleHeaderClick("status")}>
                    Status
                    {sortField === "status" &&
                      (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                  <th
                    scope="col"
                    onClick={() => handleHeaderClick("created_by")}
                  >
                    Created By
                    {sortField === "created_by" &&
                      (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                  <th
                    scope="col"
                    onClick={() => handleHeaderClick("create_dt")}
                  >
                    Create Date
                    {sortField === "create_dt" &&
                      (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span>Open/Hold</span>
                  </th>
                </tr>
              </thead>
              {tessituraBatches && (
                <tbody className="divide-y divide-gray-200">
                  {filteredBatches?.map((batch) => (
                    <tr key={batch.batch_no}>
                      <td>{batch.batch_no}</td>
                      <td>{batch.batch_type}</td>
                      <td>{batch.status === "H" ? "Held" : "Open"}</td>
                      <td>{batch.created_by}</td>
                      <td>{new Date(batch.create_dt).toLocaleDateString()}</td>
                      <td>
                        <button
                          onClick={(event) => {
                            event.preventDefault();
                            handleEditBatch(
                              batch.batch_no,
                              batch.status === "O" ? "H" : "O"
                            );
                          }}
                        >
                          {batch.status === "O" ? "Hold" : "Open"}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
